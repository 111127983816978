import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/(content)/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/lib/_fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./gilroy-regular.ttf\",\"display\":\"swap\",\"variable\":\"--font-gilroy\"}],\"variableName\":\"gilroy\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/lib/_fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./gilroy-bold.ttf\",\"display\":\"swap\",\"variable\":\"--font-gilroy-bold\"}],\"variableName\":\"gilroyBold\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/lib/_fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./aa-bebas-neue.ttf\",\"display\":\"swap\",\"variable\":\"--font-bebas\"}],\"variableName\":\"bebasFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/src/app/(content)/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MenuButton"] */ "/app/src/components/(buttons)/menu-button.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/cookies/cookies.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderNav"] */ "/app/src/components/header/header-nav.tsx");
